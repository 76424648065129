/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * 项目预算50
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-07-08 19:07:30
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */

import React, { useState, useRef } from 'react';
import _ from 'lodash';
// Tooltip
import { Pagination, Input } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './budgetIndex.scss';
import { useMount } from '../../../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

// eslint-disable-next-line no-unused-vars
export default function ProjectBudgetIndex({ msg, setSlotChatFooter, id }) {
  ProjectBudgetIndex.propTypes = {
    msg: PropTypes.object,
    setSlotChatFooter: PropTypes.func,
    id: PropTypes.string,
  };
  // eslint-disable-next-line max-len
  // const contractTipsTxt = '<div><div>当前利润：当前利润 = 当前利润</div><div>当前收入：当前收入 = 销售收入</div><div>当前支出：当前支出 = 采购成本+费用支出+报销支出</div></div>';
  let [content, setContent] = useState(msg.info?.list || []); // 接口返回的list
  let [saveContent, setSaveContent] = useState(_.cloneDeep(msg.info?.list || [])); // 接口返回的list
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(msg.info?.current_page || 1); // 当前页数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 3); // 每页展示的条数 
  const [totalPage, setTotalPage] = useState(msg.info?.total || 1); // 一共多少页
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [searchVisable, setSearchVisable] = useState(false); // 显示表格头部搜索
  const [currentInputIndex, setInputIndex] = useState(null); // 搜索框name
  const [currentSearchKey, setCurrentSearchKey] = useState(null); // 搜索框name
  const [searchSaveKey, setSearchSaveKey] = useState(null); // 搜索框name
  const inputRef = useRef(null); // 文本框聚焦
  // console.log(msg, 'ZKGJProjectBudgetRemind', content, msg.info?.list, totalPage); 
  useMount(() => {
    // console.log(msg, 'content');
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  function getDateList(perPage, currentPage, search_key) {
    // eslint-disable-next-line no-unreachable
    setLoadingStatus(true);
    setInputIndex(null);
    const params = {
      ...msg.info.parameter,
      per_page: perPage,
      current_page: currentPage,
    };
    params.search_key = search_key;
    request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
      params,
    }).then((res) => {
      // console.log('res', res);
      // eslint-disable-next-line no-prototype-builtins
      if (res && res.hasOwnProperty('info')) {
        if (msg.title !== res.title) msg.title = res.title;
        const arr1 = res.info.list || [];
        msg.info.current_page = res.info.current_page || 1;
        msg.info.per_page = res.info.per_page || 3;
        msg.info.parameter = res.info.parameter;
        setTotalPage(res.info.total);
        setCurrent(res.info.current_page || 1);
        setPrePage(res.info.per_page || 3);
        setContent([...arr1]);
        setSaveContent(_.cloneDeep(arr1)); // 旧数据
      }
      setLoadingStatus(false);
    });
  }

  // 切换页码
  function onChangePageSize(page) {
    setCurrent(page);
    setPageStatus(false);
    getDateList(prePage, page, searchSaveKey);
  }
  // 修改项目预算的值
  function setInputValue(val, index) {
    if (val) {
      // eslint-disable-next-line no-restricted-globals
      // content[index].budget_money = !isNaN(val) ? val : val.slice(0, val.length - 1);
      // eslint-disable-next-line prefer-destructuring
      content[index].budget_money = `${val.replace(/[^\-?\d.]/g, '')}`.match(/^\d*(\.?\d{0,2})/g)[0];
    } else {
      content[index].budget_money = '';
    }
    setContent([...content]);
    // console.log(saveContent[index].budget_money, 'qqqqq', content[index].budget_money);
  }

  // function SlotFooter() {
  //   return (
  //     <div style={{ color: '#C9CED1', marginTop: '8px', marginLeft: '8px' }}>
  //       已保存  
  //       <span style={{ marginLeft: '8px' }}>{moment().format('HH:mm')}</span>
  //     </div>
  //   );
  // }

  // 设置项目预算
  function setProjectBudget(e, index, type) {
    if (type === 'keyDowm') {
      let newTimeout = setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        inputRef.current && inputRef.current.blur(true);
        clearTimeout(newTimeout);
      });
    }
    let isReq = false;
    if (e.target.value === '') {
      // eslint-disable-next-line no-unneeded-ternary
      isReq = saveContent[index].budget_money === null || saveContent[index].budget_money === '' ? false : true;
    } else {
      isReq = saveContent[index].budget_money !== e.target.value;
    }
    // console.log(isReq, e.target.value, saveContent, index);
    // saveContent[index].budget_money !== e.target.value
    // eslint-disable-next-line no-unreachable
    if (isReq) {
      const params = {
        // project_name: content[index].project_name,
        budget_money: e.target.value,
        project_id: content[index].project_id,
      };
      request.instanceZhangkuan.post(`${process.env.REACT_APP_ZK}/api/ac/project/budget/set`, {
        ...params,
      // eslint-disable-next-line no-unused-vars
      }).then((res) => {
        // console.log(res, '00');
        // setSlotChatFooter(SlotFooter);
        // eslint-disable-next-line no-multi-assign
        saveContent[index].budget_money = content[index].budget_money = e.target.value;
        content[index].showSaveTxtFlag = true;
        setInputIndex(index);
        content[index].showSaveTxt = moment().format('HH:mm');
        setContent([...content]);
        setSaveContent([...saveContent]); // 旧数据
        // 2s后消失
        let timeout = setTimeout(() => {
          setInputIndex(null);
          clearTimeout(timeout);
          // setContent([...content]);
        }, 2000);
      });
    }
  }

  // 设置项目预算回车
  function textareaKeyDown(e, index) {
    if (!e.shiftKey && e.keyCode === 13) {
      setProjectBudget(e, index, 'keyDowm');
    }
  }

  // 设置搜索项目名称
  function setSearchFn(e) {
    setSearchSaveKey(e.target.value);
  }

  // 搜索项目名称-列表
  function handelSetSearchKey(type) {
    if (type === 'submit') {
      setCurrentSearchKey(searchSaveKey);
      getDateList(prePage, 1, searchSaveKey);
    } else {
      setSearchSaveKey(currentSearchKey);
    }
    setSearchVisable(false);
  }
  return (
    <div className={loadingStatus ? 'v2300-ProjectBudgetIndex v1123_relative' : 'v2300-ProjectBudgetIndex'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      <header id={msg._id} className="header" dangerouslySetInnerHTML={{ __html: msg.title }} />
      <>
        <div className="budgetMain">
          <div className="budgetHd">
            <div className="tr-L name-search">
              <div>项目名称</div>
              <div className="filter-line">
                <div className="filterName-box">
                  <FilterOutlined className="FilterIcon" onClick={() => setSearchVisable(!searchVisable)} />
                  {
                    searchVisable ? (
                      <div className="search-main">
                        <div className="shaixuanTop">
                          <div>
                            <FilterOutlined /> 
                            搜索
                            <span className="shaixuanTopIcon" />
                          </div>
                        </div>
                        <div className="shaixuanCont">
                          {/* <div>搜索</div> */}
                          <Input placeholder="搜索" value={searchSaveKey} onChange={(e) => setSearchFn(e)} />
                        </div>
                        <div className="shaixuanBot">
                          <div className="shaixuan-item" onClick={() => handelSetSearchKey('cancel')}>取消</div>
                          <div className="shaixuan-item confirm" onClick={() => handelSetSearchKey('submit')}>确定</div>
                        </div>
                      </div>
                    ) : ''
                  }
                </div> 
                <div className="split-line" />
              </div>
              
            </div>
            
            <div className="tr-R">项目预算</div>
          </div>
          {
            content.map((item, index) => (
              <div className="remindList" key={index}>
                <div className="list-set_money">
                  <div className="tr-L">
                    <div className="project-name">{item.project_name || '-'}</div>
                  </div>
                  {/* item.budget_money */}
                  <div className="tr-R">
                    {/*  onChange={(e) => { setInputValue(e.target.value, index); }}  */}
                    {/* <Input type="number" key={content} onBlur={(e) => setProjectBudget(e, index)} className="budgetInputV2300PC" placeholder="请输入项目预算" onKeyDown={(e) => textareaKeyDown(e, index)} defaultValue={item.budget_money} /> */}
                    <Input ref={inputRef} maxLength="11" onBlur={(e) => setProjectBudget(e, index)} onChange={(e) => { setInputValue(e.target.value, index); }} className="budgetInputV2300PC" placeholder="请输入项目预算" onKeyDown={(e) => textareaKeyDown(e, index)} value={item.budget_money} />
                  </div>
                  {/* {
                    item.showSaveTxtFlag ? (
                      <div className="saveTxtMain">
                        <div>已保存</div>
                        <span>{item.showSaveTxt}</span>
                      </div>
                    ) : ''
                  } */}
                  {/* saveTxtMain saveTxtMainNone */}
                  {/* {
                    item.showSaveTxtFlag && index === currentInputIndex ? (
                      <div className="saveTxtMain">
                        <div>已保存</div>
                        <span>{item.showSaveTxt}</span>
                      </div>
                    ) : ''
                  } */}
                  <div className={item.showSaveTxtFlag && index === currentInputIndex ? 'saveTxtMain' : 'saveTxtMain saveTxtMainNone'}>
                    <div>已保存</div>
                    <span>{item.showSaveTxt}</span>
                  </div>
                </div>
              </div>
            ))
          }
          {
            Number(totalPage) > prePage
            ? (
              <Pagination current={Number(current)} hideOnSinglePage pageSize={Number(prePage)} onChange={(page, pageSize) => onChangePageSize(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={Number(totalPage)} />
            ) : ''
          }
        </div>
      </>
    </div>
  );
}
