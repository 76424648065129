/* eslint-disable import/prefer-default-export */
/*
 * @Author: your name
 * @Date: 2021-10-14 17:55:17
 * @LastEditTime: 2022-06-06 14:17:52
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\utils\utils.js
 */
import _ from 'lodash';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';

// 格式化数组 坏账合同 添加向下下拉图标
export const initBaddebtContractArr = (item = []) => {
  let arr = Array.isArray(item) ? item : [];
  if (Array.isArray(arr)) {
    arr = arr.map((el) => {
      el.drownFlag = false; // 控制下拉
      el.requestFlag = false; // 是否请求接口 
      el.children = []; // 子合同
      return el;
    });
  }
  return arr;
};

// 修改坏账合同下拉图标展示
export const updBaddebtContractDrownFlag = (item, index, flag) => {
  if (Array.isArray(item)) {
    item = _.cloneDeep(item).map((el, i) => {
      if (index === i) {
        el.drownFlag = !flag;
        el.requestFlag = true;
      }
      return el;
    });
  }
  return item;
};

// 坏账子合同请求-第一页
export const getBaddebtFirstList = async (msg, item) => {
  const params = {
    ...msg.info?.parameter,
    per_page: msg.info?.per_page,
    current_page: 1,
    bad_money: item.bad_money,
    customer_id: item?.custom_id,
    customer_name: item?.custom_name,
  };
  const getApiInfo = await request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/api/ar/baddebt/contract`, { params });
  return getApiInfo;
};

// 坏账查看全部子合同
export const appendBaddebtContractChild = async (msg, item, appendMessage) => {
  // eslint-disable-next-line prefer-const
  let dataInfo = await getBaddebtFirstList(msg, item);
  appendMessage({ text: JSON.stringify({ data: dataInfo, code: 0 }) }, 'left', 'text');
  // const height = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
  // const timer = setTimeout(() => {
  // eslint-disable-next-line max-len
  //   if (height !== Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight) {
  // eslint-disable-next-line max-len
  //     Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
  //   }
  //   clearTimeout(timer);
  // }, 1000);
};
