/* eslint-disable max-len */
/* eslint-disable prefer-object-spread */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * 坏账合同32
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-06-14 14:55:53
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */

import React, { useState } from 'react';
import { Pagination } from 'antd';

import PropTypes from 'prop-types';
import 'moment/locale/zh-cn';
import './baddebt.scss';
import { useMount } from '../../../../utils/hooks';
// eslint-disable-next-line
import request from '@/utils/request';
import loading from '@/images/loading.png';

export default function ArBaddebtContractChild({ msg }) {
  ArBaddebtContractChild.propTypes = {
    msg: PropTypes.object,
  };
  let [content, setContent] = useState(msg.info?.list || []); // 接口返回的list
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(msg.info?.current_page || 1); // 当前页数
  const [prePage, setPrePage] = useState(msg.info?.per_page || 3); // 每页展示的条数 
  const [totalPage, setTotalPage] = useState(msg.info?.total || 1); // 一共多少页
  const [loadingStatus, setLoadingStatus] = useState(false);
  console.log(msg, 'ArBaddebtContractList'); 
  useMount(() => {
    console.log(pageSatus, 'ArBaddebtContractListuseMount'); 
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  function getDateList(perPage, currentPage) {
    // eslint-disable-next-line no-unreachable
    setLoadingStatus(true);
    const params = {
      ...msg.info.parameter,
      per_page: perPage,
      current_page: currentPage,
    };
    request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
      params,
    }).then((res) => {
      // eslint-disable-next-line no-prototype-builtins
      if (res && res.hasOwnProperty('info')) {
        const arr1 = res.info.list || [];
        msg.info.list = arr1;
        msg.info.current_page = res.info.current_page || 1;
        msg.info.per_page = res.info.per_page || 3;
        setTotalPage(res.info.total);
        setCurrent(res.info.current_page || 1);
        setPrePage(res.info.per_page || 3);
        setContent(arr1);
      }
      setLoadingStatus(false);
    });
  }

  // 切换页码
  function onChangePageSize(page) {
    setCurrent(page);
    setPageStatus(false);
    getDateList(prePage, page);
  }

  return (
    <div className={loadingStatus ? 'v2200-ArBaddebtContractIndex v1123_relative' : 'v2200-ArBaddebtContractIndex'}>
      <div className={loadingStatus ? 'mask' : 'none'} />
      <div className={loadingStatus ? 'loading' : 'none'}>
        <img src={loading} alt="" />
      </div>
      {
        Array.isArray(content) && content.length ? (
          <div className="baddebtChildren">
            {
              content.map((el, index) => (
                <div className="contract-ct" key={index}>
                  <div>
                    <span className="fontW700">{el.contract_name}</span>
                    <span className="mlr5">|</span>
                    跟进人：
                    {el.followup_name}
                  </div>
                  <div className="mt8 money-date">
                    <div className="w260">
                      坏账金额：
                      <span className="color-FF475A">
                        <span className="v2210_moneyUnitMR3">¥</span>
                        {`${el.bad_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                      </span>
                      <span className="mlr5">/</span>
                      <span className="v2210_moneyUnitMR3">¥</span>
                      {`${el.contract_money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                    </div>
                    <div>
                      坏账日期：
                      {el.max_date || '-'}
                    </div>
                  </div>
                  <div className="mt8">
                    坏账原因：
                    {el.reason}
                  </div>
                </div>
              ))
            }
            {
              Number(totalPage) > msg.info.per_page 
              ? (
                <Pagination current={Number(current)} hideOnSinglePage pageSize={Number(prePage)} onChange={(page, pageSize) => onChangePageSize(page, pageSize)} className="v1124_pageinationPC" size="small" showSizeChanger={false} total={Number(totalPage)} />
              ) : ''
            }
          </div>
        ) : ''
      }
    </div>
  );
}
