/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/*
 * mobile 费用报销借款 42
 * @Author: your name
 * @Date: 2022-04-14 18:17:47
 * @LastEditTime: 2022-07-07 21:16:16
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \signser-web\src\containers\Contacts\index.js
 */
import React, { useState, useRef } from 'react';
import { Input } from 'antd-mobile';
import { CaretDownOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import 'moment/locale/zh-cn';
import './remind.scss';
import { useMount } from '../../../../../utils/hooks';
import right from '@/images/icon-right.png';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';
import loading from '@/images/loading.png';
import contractTipImg from '@/images/contract-tips_v2300.png';

let start = false; 
export default function MobileZKGJProjectBudgetRemind({ msg }) {
  MobileZKGJProjectBudgetRemind.propTypes = {
    msg: PropTypes.object,
  };
  // eslint-disable-next-line quotes
  let [force, setForce] = useState(msg.info?.list);
  const [pageSatus, setPageStatus] = useState(true);
  const [current, setCurrent] = useState(1);
  const [loadingStatus, setLoadingStatus] = useState(false);
  let [pickArr, setPickArr] = useState([]);
  const inputRef = useRef(null); // 文本框聚焦
  // console.log(force, msg);
  useMount(() => {
    if (current > 1) {
      request.instanceZhangkuan.get(`${process.env.REACT_APP_SIGNSERPUSH}/${msg.info.api}`, {
        params: {
          ...msg.info.parameter,
          per_page: msg.info.per_page,
          current_page: current,
        },
      }).then((res) => {
        setLoadingStatus(false);
        // eslint-disable-next-line no-prototype-builtins
        if (res && res.hasOwnProperty('info')) {
          let list = res.info.list || [];
          if (current < 3) {
            force = [...force, ...list];
            setForce(force);
          } else {
            setForce([...force, ...list]);
          }
        }
      });
    }
  }, [current]);

  useMount(() => {
    let timer;
    if (pageSatus) {
      timer = setTimeout(() => {
        Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollTop = Array.from(document.getElementsByClassName('PullToRefresh'))[0].scrollHeight;
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {
      // eslint-disable-next-line
      timer && clearTimeout(timer);
    };
  });

  // 下一页
  function nextPage() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        if (pickArr.length) {
          setPickArr([]);
          return;
        }
        setLoadingStatus(true);
        setCurrent(current + 1);
        setPageStatus(false);
      }
      clearTimeout(time);
    }, 100);
  }

  // 收起
  function packUp() {
    start = false;
    const time = setTimeout(() => {
      if (!start) {
        setPickArr(force.slice(0, msg.info.per_page ? msg.info.per_page : 3));
      }
      clearTimeout(time);
    }, 100);
  }
  function onTouchMove() {
    start = true;
  }

  // 文本框输入
  function openSetBudgetInput(index1) {
    let content = (pickArr.length ? pickArr : force)[index1];
    content.showBudgetSetInput = true;
    content.save_budget_money = content.budget_money || '';
    if (pickArr.length) {
      pickArr[index1] = content;
      setPickArr([...pickArr]);
    }
    force[index1] = content;
    setForce([...force]);
    let timeout = setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      inputRef.current && inputRef.current.focus({ cursor: 'start' });
      clearTimeout(timeout);
    });
  }
  
  // 设置文本框的值
  function setInputValue(e, index1) {
    // console.log(e, index1, index2, 'setInputValue');
    let content = (pickArr.length ? pickArr : force)[index1];
    let val = e?.target?.value || e;
    if (val) {
      // eslint-disable-next-line prefer-destructuring
      content.save_budget_money = `${val.replace(/[^\-?\d.]/g, '')}`.match(/^\d*(\.?\d{0,2})/g)[0];
    } else {
      content.save_budget_money = '';
    }
    if (pickArr.length) {
      pickArr[index1] = content;
      setPickArr([...pickArr]);
    }
    force[index1] = content;
    // console.log(force, 'force');
    setForce([...force]);
  }

  // 设置项目预算
  async function setProjectBudget(e, index1) {
    let content = (pickArr.length ? pickArr : force)[index1];
    if (Number(e) !== Number(content.budget_money) || ((content.budget_money === null || content.budget_money === '') && e)) { 
      const params = {
        budget_money: e,
        project_id: content.id,
      };
      // eslint-disable-next-line no-unused-vars
      let res = await request.instanceZhangkuan.post(`${process.env.REACT_APP_ZK}/api/ac/project/budget/set`, {
        ...params,
      });
      content.budget_money = e;
    }
    content.showBudgetSetInput = false;
    if (pickArr.length) {
      pickArr[index1] = content;
      setPickArr([...pickArr]);
    }
    force[index1] = content;
    setForce([...force]);
    // console.log(e, index1, index2, 'setProjectBudget');
  }

  return (
    <div className="mobileV2300ZKGJProjectBudgetRemind" style={{ minWidth: 'calc(540rem/75)', paddingBottom: Number(msg.info.total) > msg.info.per_page ? 'calc(52rem/75)' : '0' }}>
      {
        // eslint-disable-next-line max-len
        Array.isArray((pickArr.length ? pickArr : force)) && (pickArr.length ? pickArr : force).length ? (
          <>
            {/* 内容部分 */}
            <div className="list-main">
              {
                (pickArr.length ? pickArr : force).map((item, index) => (
                  <div className="remindList" key={index}>
                    <div className="title-flex">
                      <div className="list-title">{item.project_name || '-'}</div>
                      <div className="contractTipImg-main">
                        <img src={contractTipImg} />
                        <div className="tooltip-main">
                          <div className="tooltip-content">
                            {
                              msg && msg.info && msg.info.formula_explain.map((formulaItem, formulaIndex) => <div key={formulaIndex}>{formulaItem}</div>)
                            }
                            {/* icon */}
                            <div className="icon-down"><CaretDownOutlined /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-money">
                      <div className="pre-money budgerFlex">
                        <span className="pre-money_label">项目预算：</span>
                        {
                          item.showBudgetSetInput ? (
                            <div className="setBudgetMain">
                              <Input ref={inputRef} onBlur={(e) => setProjectBudget(e.target.value, index)} onChange={(e) => { setInputValue(e, index); }} className="budgetInputV2300Mobile" value={item.save_budget_money} maxLength="11" />
                            </div>
                          ) : (
                            item.budget_money ? (
                              <>
                                <span className="v2210_moneyUnitMobileMR3">¥</span>
                                {`${Number(item.budget_money)?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                <EditOutlined className="setBudgetTxtIcon" onClick={() => openSetBudgetInput(index)} />
                              </>
                            ) : (
                              <span className="setBudgetTxt" onClick={() => openSetBudgetInput(index)}>点击输入预算</span>
                            )
                          )
                        }
                      </div>
                      <div className="pre-money mt8">
                        <span className="pre-money_label">剩余预算：</span>
                        {
                          item.budget_money && (Number(item.budget_money) - Number(item.sjzc)) ? (
                            <span className="remainingMoneyTxt">
                              <span className="v2210_moneyUnitMobileMR3">¥</span>
                              {`${(Number(item.budget_money) - Number(item.sjzc))?.toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                            </span>
                          ) : '-'
                        }
                      </div>
                      <div className="pre-money mt8">
                        <span className="pre-money_label">
                          {
                            msg?.info?.parameter?.old_project_version === '1' ? '当前利润：' : '实际利润：'
                          }
                        </span>
                        {
                          item.sjlr ? (
                            <>
                              <span className="v2210_moneyUnitMobileMR3">¥</span>
                              {`${item.sjlr?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                            </>
                          ) : '-'
                        }
                      </div>
                      <div className="pre-money mt8">
                        <span className="pre-money_label">
                          {
                            msg?.info?.parameter?.old_project_version === '1' ? '当前收入：' : '实际收入：'
                          }
                        </span>
                        {
                          item.sjsr ? (
                            <>
                              <span className="v2210_moneyUnitMobileMR3">¥</span>
                              {`${item.sjsr?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                            </>
                          ) : '-'
                        }
                      </div>
                      <div className="pre-money mt8">
                        <span className="pre-money_label">
                          {
                            msg?.info?.parameter?.old_project_version === '1' ? '当前支出：' : '实际支出：'
                          }
                        </span>
                        {
                          item.sjzc ? (
                            <>
                              <span className="v2210_moneyUnitMobileMR3">¥</span>
                              {`${item.sjzc?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                            </>
                          ) : '-'
                        }
                      </div>
                    </div>  
                
                  </div>
                ))
              }
            </div>
            {/* 分页 */}
            <div className={Number(msg.info.total) > msg.info.per_page ? 'v1124_filterBottomMobile v2200_filterBottomMobile' : 'none'}>
              {/* <div className="filter" /> */}
              <div className="flex ismore" style={{ height: 'calc(48rem/75)' }}>
                {/* eslint-disable-next-line */}
                <div className={current >= Number(msg.info.total_page) && pickArr.length === 0 ? 'none' : 'more'} onTouchStart={() => loadingStatus ? '' : nextPage()} onTouchMove={onTouchMove}>{loadingStatus ? <div className="loading"><img src={loading} alt="" /></div> : '查看更多'}</div>
                <div className={current > 1 && pickArr.length === 0 ? 'flex align-item left pick-style' : 'none'} style={{ position: 'absolute', right: 0 }} onTouchStart={packUp} onTouchMove={onTouchMove}>
                  <div className="pack-up">
                    收起
                  </div>
                  <div style={{ width: 'calc(28rem/75)', height: 'calc(28rem/75)' }}>
                    <img src={right} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : ''
      }
    </div>
  );
}
