/*
 * @Author: your name
 * @Date: 2022-01-25 16:03:20
 * @LastEditTime: 2022-05-31 18:09:11
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \Signser-web\src\containers\MobileStaff\index.js
 */
// 移动端人工客服
import React from 'react';
import PropTypes from 'prop-types';
import '@/style/MobileStaff.scss';
import axios from 'axios';
import qs from 'querystring';
// 人工客服二维码展示
import CustomServiceQrCode from '../v1.1.24_PC/customServiceQrCode';
import { useScrollBottom, useMount } from '../../utils/hooks';
// eslint-disable-next-line import/extensions
import { openLink } from '@/utils/dd_methods.js';

// eslint-disable-next-line max-len
export default function MobileStaff({ msg, pcSend, setSlotChatFooter, appendMessage, kfStatus, textID, deleteMsg }) {
    const params = qs.parse(window.location.search.slice(1)) || {};
    const proxy = process.env.REACT_APP_ZKGJ_PROXY || process.env.REACT_APP_ZKGJ;
    MobileStaff.propTypes = {
      msg: PropTypes.object,
      appendMessage: PropTypes.func,
      kfStatus: PropTypes.number, // kf为1时用户主动进入客服 为0的时候是Signser没有识别出来
      textID: PropTypes.string,
      pcSend: PropTypes.func,
      deleteMsg: PropTypes.func,
      setSlotChatFooter: PropTypes.func,
    };
    let groupUrl = ''; // 获取接口请求的客服url
    let returnStatus = false; // 是否到了一秒钟
    useMount(() => {
      if (msg?.is_open_cs === 1) {
        if (msg.info.groupUrl === '') {
          const manualServiceData = { ...msg.info };
          setSlotChatFooter(<CustomServiceQrCode manualServiceData={manualServiceData} device="mobile" />);
        } else {
          try {
            openLink(msg.info.groupUrl);
          // eslint-disable-next-line no-empty
          } catch (error) {
          }
        }
        return;
      }  
      if (kfStatus === 1) {
        deleteMsg(textID);
        goHref();
      }
    });
    useScrollBottom();
    function goHref() {
      axios({
        method: 'POST',
        url: `${proxy}/laravel/user/getGroupCode`,
        headers: {
          Authorization: params.token || process.env.REACT_APP_TOKEN,
        },
      }).then((res) => {
        if (res.data.code === 0) {
          if (params.device !== 'Mac') {
            appendMessage({ text: '已为您转接至钉钉客服群' }, 'left', 'kf');
          }
          if (returnStatus) {
            openLink(res.data.data.groupUrl);
          } else {
            groupUrl = res.data.data.groupUrl;
          }
        } else if (returnStatus) {
            appendMessage({ text: '人工客服忙...' }, 'left', 'text');
          } else {
            groupUrl = 0;
          }
      }).catch(() => {
        // groupUrl = '';
        // 展示客服电话和二维码
        groupUrl = 0; 
      });
      // if (params.device !== 'Mac') {
      //   appendMessage({ text: '已为您转接至钉钉客服群' }, 'left', 'kf');
      // }
      const time = setTimeout(() => {
        returnStatus = true;
        if (groupUrl) {
          openLink(groupUrl);
        } else if (groupUrl === 0) {
          appendMessage({ text: '人工客服忙...' }, 'left', 'text');
        }
        clearTimeout(time);
      }, 1000);
    }
    
    return (
      <div className={kfStatus === 1 ? 'none' : 'MobileStaff'}>
        <div>
          <span className="link" onClick={() => pcSend('转人工')}>转人工</span>
          <span className="no-link">或在当前页面重新提问</span>
        </div>
      </div>
    );
}
