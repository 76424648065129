/* eslint-disable no-shadow */
/* eslint-disable max-len */
/*
 * @Author: your name
 * @Date: 2021-10-14 17:55:17
 * @LastEditTime: 2022-05-25 14:56:53
 * @LastEditors: 张立鹏 2475171028@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \signser\src\utils\utils.js
 */
import _ from 'lodash';
// eslint-disable-next-line import/extensions
import request from '@/utils/request';
import specialBill from '@/images/special_bill_1124.png';
import ordinaryBill from '@/images/ordinary_bill_1124.png';
import otherBill from '@/images/other_bill_1124.png';
// 展示icon
// 增值税普通发票 增值税专用发票 增值税电子普通发票 增值税电子专用发票 其他
// eslint-disable-next-line import/prefer-default-export
export const getIconImg = (txt) => {
  let imgIcon = specialBill;
  switch (txt) {
    case '增值税普通发票':
      imgIcon = ordinaryBill;
      break;
    case '增值税专用发票':
      imgIcon = specialBill;
      break;
    case '增值税电子普通发票':
      imgIcon = ordinaryBill;
      break;
    case '增值税电子专用发票':
      imgIcon = specialBill;
      break;  
    case '其他':
      imgIcon = otherBill;
      break;    
    default:
      break;  
  }
  return imgIcon;
};
// 格式化数组 合同 添加向下下拉图标
export const initArr = (item = []) => {
  let arr = Array.isArray(item) ? item : [];
  if (Array.isArray(arr)) {
    arr = arr.map((el) => {
      // el.requestFlag = true; // 默认不发送接口
      if (Array.isArray(el.contracts)) {
        el.contracts_list = el.contracts.slice(0, 3);
        el.showDrownImg = el.relation_count > 3;
        el.requestFlag = el.relation_count <= 3;
      }
      el.drownFlag = false;
      return el;
    });
  }
  return arr;
};

// 格式化数组 合同 添加向下下拉图标
export const initMobileArr = (item = []) => {
  let arr = Array.isArray(item) ? item : [];
  if (Array.isArray(arr)) {
    arr = arr.map((el) => {
      el.drownFlag = false; // 控制下拉
      el.requestFlag = true; // 控制是否请求接口
      if (Array.isArray(el.contracts) && el.relation_count > 3) {
        el.requestFlag = false;
      } 
      return el;
    });
  }
  return arr;
};

// leb 所有状态值
/* leb 取值:  0：新建合同发票 1：申请合同发票 2：费用新建发票 3：费用申请发票 4：报销申请发票 5：新建其他发票 6：申请其他发票 */
export const lebStatusObj = {
    fee: [2, 3, 4],
    contract: [0, 1],
    project: [5, 6],
    noFee: [0, 1, 5, 6],
};

// 修改下拉图标展示
export const updMobileDrownFlag = (item, index, flag, newArr = []) => {
  if (Array.isArray(item)) {
    item = _.cloneDeep(item).map((el, i) => {
      if (index === i) {
        el.drownFlag = !flag;
        el.requestFlag = true;
        if (Array.isArray(el.contracts)) {
          el.contracts = el.contracts.length > 3 ? el.contracts : [...el.contracts, ...newArr];
        }
      }
      return el;
    });
  }
  return item;
};

// nav设置
export const getBillNavList = (msg, initState) => {
  let tabData = [{ navTitle: '已开票', navKey: '0', msg: { info: { list: [] } }, headerTitle: null }, { navTitle: '未开票', navKey: '1', msg: { info: { list: [] } }, headerTitle: null }];
  tabData = tabData.map(((el) => {
    if (el.navTitle === initState) {
      el.headerTitle = msg.title;
      el.msg = msg;
    }
    return el;
  }));
  return tabData;
};

// 设置nav数据项
export const setBillNewNavList = (tabData, msg, initState) => {
  // 1.未开票 0开票
  let currentStateArr = [
    { navTitle: '已开票', unbill: 0 },
    { navTitle: '未开票', unbill: 1 },
  ]; // 存储nav
  currentStateArr = currentStateArr.filter((el) => el.navTitle !== initState);
  const params = {
    per_page: msg.info.per_page || '3',
    current_page: msg.info.current_page || 1,
    ...msg.info.parameter,
  };
  if (Array.isArray(currentStateArr)) {
    currentStateArr.map(async (el) => {
      const item = await request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, { params: { ...params, unbill: el.unbill } });
      tabData[el.unbill].msg = { ...item };
      tabData[el.unbill].headerTitle = item.title;
    });
  }
  return tabData;
};

// 禁止移动端滑动时触摸时间发生
export const stopTouchendPropagationAfterScroll = () => {
  let locked = false;
  // eslint-disable-next-line no-unused-vars
  window.addEventListener('touchmove', (e) => {
    // eslint-disable-next-line max-len
    // locked || (locked = true, window.addEventListener('touchend', stopTouchendPropagation, true));
    if (!locked) {
      locked = true;
      window.addEventListener('touchend', stopTouchendPropagation, true);
    }
  }, true);
  function stopTouchendPropagation(e) {
    e.stopPropagation();
    window.removeEventListener('touchend', stopTouchendPropagation, true);
    locked = false;
  }
};

// 设置对话框底部按钮-查看明细
export const setChatFooter = (msg, appendMessage, setSlotChatFooter) => {
  // 查看开票详情明细---添加内容
  // eslint-disable-next-line no-redeclare
  function changeDetail(msg, value, appendMessage) {
    if (msg && msg.info) {
      const params = {
        ...msg.info.parameter,
        per_page: msg.info?.per_page,
        current_page: 1,
        is_detail: value,
        total: msg.info?.total,
      };
      request.instanceZhangkuan.get(`${process.env.REACT_APP_ZK}/${msg.info.api}`, {
        params,
      }).then((res) => {
        appendMessage({ text: JSON.stringify({ data: res, code: 0 }) }, 'left', 'text');
      });
    }
  }
  // eslint-disable-next-line no-inner-declarations
  function SlotFooter() {
    return (
      <div className="slotFooterMain-V2110">
        <div className="footerItem" onClick={() => changeDetail(msg, 1, appendMessage)}>仅看有开票明细的</div>
        <div className="footerItem" onClick={() => changeDetail(msg, 0, appendMessage)}>仅看没有开票明细的</div>
      </div>
    );
  }
  setSlotChatFooter(SlotFooter);
};
